<template>
  <div class="task">
    <div class="padding_top"></div>

    <b-container v-if="tasks && type">
      <b-row align-h="center">
        <b-col cols="12" md="8">
          <div class="videoTask embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              :src="'https://www.youtube.com/embed/' + tasks.active_task.video"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="12" md="8">
          <h1 class="task__title">
            {{
              type == "exe"
                ? tasks.active_task.title
                : tasks.editing_tasks[active].task.title
            }}
          </h1>

          <h2 class="task__description">
            {{
              type == "exe"
                ? tasks.active_task.description
                : tasks.editing_tasks[active].task.description
            }}
          </h2>

          <p
            class="task__text"
            v-html="
              type == 'exe'
                ? tasks.active_task.text
                : tasks.editing_tasks[active].task.text
            "
          ></p>
        </b-col>
      </b-row>

      <b-row class="answers_row" align-h="center">
        <b-col cols="12" md="8">
          <b-form class="modal__form" @submit="complete">
            <div v-if="type == 'exe'">
              <div
                v-for="(question, index) in tasks.active_task.questions"
                :key="index"
              >
                <p>{{ question.question }}</p>
                <b-form-textarea
                  id="textarea"
                  v-model="question.answer"
                  placeholder="Введите ответ..."
                  rows="3"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </div>
            </div>

            <div v-if="type == 'edit'">
              <div
                v-for="(item, index) in tasks.editing_tasks[active].answers"
                :key="index"
              >
                <p>{{ item.question.question }}</p>
                <b-form-textarea
                  id="textarea"
                  v-model="item.answer.answer"
                  placeholder="Введите ответ..."
                  rows="3"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </div>
            </div>

            <b-form-group id="file">
              <b-form-file
                v-model="answerFile"
                :state="Boolean(answerFile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                required
              ></b-form-file>
            </b-form-group>

            <b-button
              v-if="type == 'exe'"
              type="submit"
              class="main__button modal_form__buttom"
              :disabled="loading"
            >
              Задание выполнено
            </b-button>

            <b-button
              v-if="type == 'edit'"
              type="button"
              @click="edit"
              class="main__button modal_form__buttom"
              :disabled="loading"
            >
              Задание выполнено
            </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "Task",
  data() {
    return {
      answerFile: null,
    };
  },
  methods: {
    ...mapActions(["getTasks", "editTask", "getUser"]),
    async complete(e) {
      e.preventDefault();

      var answers = [];

      for (const answer of this.tasks.active_task.questions) {
        answers.push({
          question_id: answer.id,
          answer: answer.answer,
        });
      }

      try {
        this.$store.commit("SET_LOADING", true);

        let filePath;

        let formData = new FormData();
        formData.append("answerFile", this.answerFile);

        filePath = await axios
          .post("load_answer_file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            return res.data;
          });

        await axios.put(`tasks/${this.tasks.active_task.id}`, {
          answers: answers,
          filePath: filePath,
        });

        this.$router.push({ name: "Tasks" });

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
    async edit() {
      var answers = [];

      for (const answer of this.tasks.editing_tasks[this.active].answers) {
        answers.push({
          id: answer.answer.id,
          answer: answer.answer.answer,
        });
      }

      await this.editTask({
        id: this.tasks.editing_tasks[this.active].answers[0].answer
          .completed_task_id,
        answers: answers,
      }).then(() => {
        this.$router.push({ name: "Tasks" });
      });
    },
  },
  computed: {
    tasks() {
      return this.$store.state.bkonline.tasks;
    },
    user() {
      return this.$store.state.user.user;
    },
    type() {
      return localStorage.getItem("typeTask");
    },
    active() {
      return localStorage.getItem("activeTask");
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  async mounted() {
    if (localStorage.getItem("token")) {
      try {
        this.$store.commit("SET_LOADING", true);
        await this.getUser();

        if (this.tasks == null) {
          await this.getTasks();
        }

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    }
  },
};
</script>

<style lang="scss">
.task__title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
}
.task__text {
  font-weight: 400;
  white-space: pre-wrap;
  margin-bottom: 10vh;
  font-size: 20px;
  color: $light_gray__dark;
}
</style>
